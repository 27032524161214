import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { IoSearch, IoMenu, IoClose } from "react-icons/io5";
import axios from "axios";

// Custom imports
import "./header.scss";

// JSON Import
//import navigation from "../../assets/json/navigation/navigation.json";

const Header = () => {
  const [mobileMenu, setMobileMenu] = useState<boolean>(false);
  const [navList, setNavList] = useState<any | null>(null);

  const toggleMobileMenu = () => {
    setMobileMenu(!mobileMenu);
  };

  useEffect(() => {
    axios.get("/json/navigation/navigation.json").then((res) => {
      console.log("nav list - ", res.data);
      setNavList(res.data);
    });
  }, []);

  return (
    <header className="full-width header">
      <div className="container">
        <div className="row">
          <div className="col-6 col-md-3 col-lg-2">
            <div className="full-width branding">
              <button
                type="button"
                className="mobile-menu-toggle-btn btn d-lg-none"
                onClick={() => toggleMobileMenu()}
              >
                <IoMenu />
              </button>
              <Link to="/" className="branding-link">
                <div className="branding-logo full-width">Logo</div>
              </Link>
            </div>
          </div>
          <div className="col-1 col-md-6 col-lg-8">
            <div
              className={
                mobileMenu
                  ? "full-width main-menu-block show-menu"
                  : "full-width main-menu-block"
              }
            >
              <i
                className="mobile-menu-overlay"
                onClick={() => toggleMobileMenu()}
              ></i>
              <ul className="main-menu list-unstyled full-width">
                {navList
                  ? navList.map((data: any, index: number) => (
                      <li key={index + "navlnk"}>
                        <NavLink
                          to={"/" + data.slug}
                          className="menu-link"
                          activeClassName="active"
                          onClick={() => toggleMobileMenu()}
                          title={data.tooltip}
                        >
                          {data.title}
                        </NavLink>
                      </li>
                    ))
                  : ""}
              </ul>
            </div>
          </div>
          <div className="col-5 col-md-3 col-lg-2">
            {/* <div className="full-width search-block">
              <button className="search-toggle-btn">
                <div className="search-textbox"></div>
                <div className="search-icon">
                  <IoSearch />
                </div>
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
