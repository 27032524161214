import axios from "axios";
import React, { useState, useEffect } from "react";

// Custom imports
import { couponDealBtn } from "../../components/Common";
import Coupon from "../../components/Coupon";
import PopupCouponDeal from "../../components/PopupCouponDeal";

const CategoryDetail = () => {
  const [catCouponList, setCatCouponList] = useState<any | null>(null);
  const [selectCoupon, setSelectCoupon] = useState<any | null>(null);
  const [popupToggle, setPopupToggle] = useState<boolean>(false);
  const [showPopup, setShowPopup] = useState<boolean>(true);

  const slug = window.location.pathname.split("/")[2];
  const hashCode = window.location.hash.split("#")[1];

  const getCatList = () => {
    const data = axios
      .get("/json/categoriesDetail/" + slug + ".json")
      .then(async (res) => {
        return res.data;
      });

    return data;
  };

  const closePopup = () => {
    setSelectCoupon(null);
    setPopupToggle(false);
    setShowPopup(false);
  };

  useEffect(() => {
    getCatList().then((res) => {
      setCatCouponList(res);
    });

    if (hashCode && showPopup === true) {
      getCatList().then((res) => {
        // setCouponList(res);
        console.log("inside hashcode - ", res);
        setPopupToggle(true);
        res.map((data: any) => {
          if (data.id === hashCode) {
            setSelectCoupon(data);
          }
        });
      });
    }
  }, []);

  return (
    <>
      <div className="full-width store-coupons-header">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="full-width store-detail-block">
                <div className="store-detail-header full-width">
                  {/* <div className="store-brand">
                    {catCouponList ? (
                      <img src={catCouponList[0].image} alt="" />
                    ) : (
                      ""
                    )}
                  </div> */}
                  <div className="store-heder-title-info">
                    <h1 className="store-heder-title full-width">
                      {catCouponList ? catCouponList[0].catName : ""}
                    </h1>
                    <p>{catCouponList ? catCouponList[0].catSlug : ""}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="full-width store-coupons-block">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="full-width store-list-block">
                <ul className="store-list list-unstyled full-width">
                  {catCouponList
                    ? catCouponList.map((data: any, index: number) => (
                        <li key={index + "stcop"}>
                          <Coupon
                            link={data.link}
                            image={data.image}
                            couponTitle={data.couponTitle}
                            couponDesc={data.couponDesc}
                            store={data.store}
                            type={data.type}
                            couponCode={
                              data.couponCode ? data.couponCode.slice(-3) : ""
                            }
                            bigText={data.bigText}
                            smallText={data.smallText}
                            promoCouonCode={data.promoCouonCode}
                            btnOnClick={() => couponDealBtn(data)}
                          />
                        </li>
                      ))
                    : ""}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {selectCoupon && popupToggle === true ? (
        <PopupCouponDeal
          store={selectCoupon.store}
          storeImage={selectCoupon.image}
          type={selectCoupon.type}
          couponCode={selectCoupon.couponCode}
          show={popupToggle}
          closePopup={() => closePopup()}
          link={selectCoupon.link}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default CategoryDetail;
