import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

//Custom imports
import "./home.scss";

//demo starts
import logo1 from "../../assets/images/logos/1.jpeg";
import logo2 from "../../assets/images/logos/2.jpg";
import logo3 from "../../assets/images/logos/3.png";
import Coupon from "../../components/Coupon";
//demo ends

const HomePage = () => {
  const [todayTopCoupons, setTodayTopCoupons] = useState<any | null>(null);
  const [staffPicks, setStaffPicks] = useState<any | null>(null);
  const [todayTrending, setTodayTrending] = useState<any | null>(null);

  const couponDealBtn = (data: any) => {};

  const getTodayTopCoupons = () => {
    const dataList = axios
      .get("/json/home/home-today-top-coupons.json")
      .then((res) => {
        return res.data;
      });

    return dataList;
  };

  const getStaffPicks = () => {
    const dataList = axios
      .get("/json/home/home-staff-picks.json")
      .then((res) => {
        return res.data;
      });

    return dataList;
  };

  const getTodayTrendingCoupons = () => {
    const dataList = axios
      .get("/json/home/home-today-trending-coupons.json")
      .then((res) => {
        return res.data;
      });

    return dataList;
  };

  useEffect(() => {
    getTodayTopCoupons().then((res) => {
      setTodayTopCoupons(res);
    });

    getStaffPicks().then((res) => {
      setStaffPicks(res);
    });

    getTodayTrendingCoupons().then((res) => {
      setTodayTrending(res);
    });

    return () => {};
  }, []);

  return (
    <>
      <div className="today-top-coupons full-width">
        <div
          className="container
    "
        >
          <div className="row">
            <div className="col-12">
              {/* Today Top coupons Starts */}
              <div className="top-coupons-block full-width">
                <h1 className="top-coupons-heading full-width">
                  Today's Top Coupons
                </h1>
                <div className="full-width top-coupons-list-block">
                  <ul className="top-coupons-list list-unstyled full-width">
                    {todayTopCoupons !== null
                      ? todayTopCoupons.map((data: any, index: number) => (
                          <li key={index + "ttc"}>
                            <Link
                              to={data.storeLink}
                              className="each-today-coupon full-width"
                            >
                              <div className="each-today-coupon-brand full-width">
                                <img src={data.image} alt="" />
                              </div>
                              <div className="each-today-coupon-offer full-width">
                                {data.homeCouponTitle}
                              </div>
                              {data.bestOffer ? (
                                <i className="popular-ribbon">
                                  <span>Best Offer</span>
                                </i>
                              ) : (
                                ""
                              )}
                            </Link>
                            <div className="full-width cat-title">
                              {data.storeShortName}
                            </div>
                          </li>
                        ))
                      : ""}
                  </ul>
                </div>
              </div>
              {/* Today Top coupons Ends */}

              {/* Staff Picks Starts */}
              <div className="full-width staff-picks-block">
                <div className="full-width staff-picks">
                  <h2 className="full-width staff-picks-heading">
                    Staff Picks
                  </h2>
                  <div className="full-width staf-picks-list-block">
                    <ul className="staff-picks-list list-unstyled full-width">
                      {staffPicks !== null
                        ? staffPicks.map((data: any, index: number) => (
                            <li key={index + "sp"}>
                              <Link to="" className="each-staff-coupon">
                                <div className="each-staff-coupon-brand full-width">
                                  <img src={data.image} alt="" />
                                </div>
                                <div className="each-staff-coupon-offer full-width">
                                  {data.homeCouponTitle}
                                </div>
                                {data.bestOffer ? (
                                  <i className="popular-ribbon">
                                    <span>Best Offer</span>
                                  </i>
                                ) : (
                                  ""
                                )}
                              </Link>
                              <div className="full-width each-staff-category">
                                {data.storeShortName}
                              </div>
                            </li>
                          ))
                        : ""}
                    </ul>
                  </div>
                </div>
              </div>
              {/* Staff Picks Ends` */}
            </div>
          </div>
        </div>
      </div>
      {/* Today Trending Coupons Starts  */}
      <div className="full-width today-trending-coupons">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="full-width today-trending-coupons-block">
                <h3 className="today-trending-coupons-heading heading-xxl">
                  Todays Trending Coupons
                </h3>
                <div className="full-width today-trending-coupons-list-block">
                  <ul className="today-trending-coupons list-unstyled full-width">
                    {todayTrending !== null
                      ? todayTrending.map((data: any, index: number) => (
                          <li key={index + "tcty"}>
                            <Coupon
                              link={data.link}
                              image={data.image}
                              couponTitle={data.couponTitle}
                              couponDesc={data.couponDesc}
                              store={data.store}
                              type={data.type}
                              couponCode={
                                data.couponCode ? data.couponCode.slice(-3) : ""
                              }
                              btnOnClick={() => couponDealBtn(data)}
                            />
                          </li>
                        ))
                      : ""}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Today Trending Coupons Ends  */}
    </>
  );
};

export default HomePage;
