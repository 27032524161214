import React, { useEffect, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";

// Custom imports
import Coupon from "../../components/Coupon";

// Custom imports
import "./storeDetail.scss";
import PopupCouponDeal from "../../components/PopupCouponDeal";

const StoreDetail = () => {
  const [couponList, setCouponList] = useState<any | null>(null);
  const [selectCoupon, setSelectCoupon] = useState<any | null>(null);
  const [popupToggle, setPopupToggle] = useState<boolean>(false);
  const [showPopup, setShowPopup] = useState<boolean>(true);

  const history = useHistory();

  const slug = window.location.pathname.split("/")[2];
  let hashCode = window.location.hash.split("#")[1];

  const couponDealBtn = (data: any) => {
    if (data.type === "coupon") {
      window.location = data.link;
      window.open("#" + data.id, "_blank")?.focus();
    } else if (data.type === "deal") {
      window.open(data.link, "_blank")?.focus();
      history.push("#" + data.id);
    }
  };

  const closePopup = () => {
    setSelectCoupon(null);
    setPopupToggle(false);
    setShowPopup(false);
  };

  const getCouponList = () => {
    const data = axios
      .get("/json/storeDetail/" + slug + ".json")
      .then(async (res) => {
        return res.data;
      });

    return data;
  };

  useEffect(() => {
    console.log("Path Name - ", slug, "Hash code = ", hashCode);
    getCouponList().then((res) => {
      setCouponList(res);
    });

    if (hashCode && showPopup === true) {
      getCouponList().then((res) => {
        // setCouponList(res);
        console.log("inside hashcode - ", res);
        setPopupToggle(true);
        res.map((data: any) => {
          if (data.id === hashCode) {
            setSelectCoupon(data);
          }
        });
      });
    }

    return () => {};
  }, [hashCode, popupToggle]);

  return (
    <>
      <div className="full-width store-coupons-header">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="full-width store-detail-block">
                <div className="store-detail-header full-width">
                  <div className="store-brand">
                    {couponList ? <img src={couponList[0].image} alt="" /> : ""}
                  </div>
                  <div className="store-heder-title-info">
                    <h1 className="store-heder-title full-width">
                      {couponList ? couponList[0].store : ""}
                    </h1>
                    <p>{couponList ? couponList[0].storeWebsite : ""}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="full-width store-coupons-block">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="full-width store-list-block">
                <ul className="store-list list-unstyled full-width">
                  {couponList
                    ? couponList.map((data: any, index: number) => (
                        <li key={index + "stcop"}>
                          <Coupon
                            link={data.link}
                            image={data.image}
                            couponTitle={data.couponTitle}
                            couponDesc={data.couponDesc}
                            store={data.store}
                            type={data.type}
                            couponCode={
                              data.couponCode ? data.couponCode.slice(-3) : ""
                            }
                            storeDetail={true}
                            bigText={data.bigText}
                            smallText={data.smallText}
                            promoCouonCode={data.promoCouonCode}
                            btnOnClick={() => couponDealBtn(data)}
                          />
                        </li>
                      ))
                    : ""}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {selectCoupon && popupToggle === true ? (
        <PopupCouponDeal
          store={selectCoupon.store}
          storeImage={selectCoupon.image}
          type={selectCoupon.type}
          couponCode={selectCoupon.couponCode}
          show={popupToggle}
          closePopup={() => closePopup()}
          link={selectCoupon.link}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default StoreDetail;
