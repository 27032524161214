import React from "react";

// Custom imports
import "./footer.scss";

const Footer = () => {
  return (
    <footer className="full-width footer">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="full-width footer-block">
              &copy; Copyrights 2021&nbsp;&nbsp;|&nbsp;&nbsp;All Rights Reserved
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
