import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// Custom imports
import "./categories.scss";

const Categories = () => {
  const [catList, setCatList] = useState<any | null>(null);

  const getCatList = () => {
    const data = axios.get("/json/categories/categories.json").then((res) => {
      return res.data;
    });

    return data;
  };

  useEffect(() => {
    getCatList().then((res) => {
      setCatList(res);
    });

    return () => {};
  }, []);

  return (
    <div className="full-width categories-page">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="full-width categories-page-block">
              <h1 className="categories-title full-width">Categories</h1>
              <div className="full-width categories-list-block">
                <div className="alphabet-block full-width">
                  {/* <div className="alphabet-title full-width">A</div> */}
                  {catList !== null ? (
                    <ul className="alphabet-list list-unstyled full-width">
                      {catList.map((data: any, index: number) => (
                        <li>
                          <Link
                            to={"/categories/" + data.catSlug}
                            className="cat-link-item"
                          >
                            {data.catName}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Categories;
