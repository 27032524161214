import React, { useState, useEffect } from "react";
import { IoClose } from "react-icons/io5";

// Custom imports
import "./popupCouponDeal.scss";

interface popupProps {
  store: string;
  storeImage: string;
  type: string;
  couponCode?: string | undefined;
  show: boolean;
  closePopup: any;
  link: string;
}

const PopupCouponDeal = (props: popupProps) => {
  const [couponCopied, setCouponCopied] = useState<boolean>(false);

  const copyCode = () => {
    if (props.couponCode && navigator.clipboard) {
      //document.execCommand("copy", false, "ABCDEF");
      navigator.clipboard.writeText(props.couponCode).then((res) => {
        setCouponCopied(true);

        setTimeout(() => {
          setCouponCopied(false);
        }, 3000);
      });
    }
  };

  const getdeal = () => {
    window.open(props.link, "_blank")?.focus();
  };

  useEffect(() => {
    console.log(
      "Got details - ",
      props.store,
      props.storeImage,
      props.type,
      props.couponCode,
      props.show
    );

    if (props.couponCode && navigator.clipboard) {
      //document.execCommand("copy", false, "ABCDEF");
      navigator.clipboard.writeText(props.couponCode).then((res) => {
        setCouponCopied(true);

        setTimeout(() => {
          setCouponCopied(false);
        }, 3000);
      });
    }

    return () => {
      clearTimeout();
    };
  }, []);

  return (
    <>
      <div className={props.show ? "popup show-popup" : "popup"}>
        <i className="popup-overlay"></i>

        <div className="popup-outer-block">
          <div className="popup-main-content full-width">
            <button
              type="button"
              className="popup-close-btn"
              onClick={props.closePopup}
            >
              <IoClose />
            </button>
            <div className="popup-code-brand-block full-width">
              <div className="popup-brand">
                {props.storeImage ? <img src={props.storeImage} alt="" /> : ""}
              </div>
              <div className="popup-code-action-block">
                {props.type === "coupon" ? (
                  <>
                    <div className="full-width user-info">
                      Use this coupon during checkout
                    </div>
                    <div className="code-block full-width">
                      <input
                        className="code-text-block"
                        readOnly
                        value={props.couponCode}
                      />
                      <button
                        type="button"
                        className="code-copy-btn"
                        onClick={() => copyCode()}
                      >
                        Copy Code
                      </button>
                    </div>
                    <div
                      className={
                        couponCopied
                          ? "coupon-copied-block full-width show"
                          : "coupon-copied-block full-width"
                      }
                    >
                      <div className="coupon-copied-message full-width">
                        Coupon code is copied
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="full-width user-info deal">
                      Couponcode is not required. Before tab already deal
                      applied.
                    </div>
                    <div className="code-block full-width">
                      <button
                        type="button"
                        className="code-copy-btn deal"
                        onClick={() => getdeal()}
                      >
                        Get Deal
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="code-info-block full-width">
              Paste this code at checkout at nike.com Enjoy 20% Discount on Your
              Purchase
            </div>
            {/* <div className="code-worked-status-block full-width">
                 <div className="full-width code-worked-title">Did the code work?</div>
                 <div className="code-worked-actions-block full-width">
                    button
                 </div>
              </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default PopupCouponDeal;
