import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";

// Custom imports
import "./stores.scss";
import { useState } from "react";
import { useEffect } from "react";

const Stores = () => {
  const [storesList, setStoresList] = useState<any | null>(null);

  const getStoresList = () => {
    const data = axios.get("/json/stores/stores.json").then((res) => {
      return res.data;
    });
    return data;
  };

  useEffect(() => {
    getStoresList().then((res) => {
      setStoresList(res);
    });
  }, []);

  return (
    <>
      <div className="store-list-page full-width">
        <div
          className="container
    "
        >
          <div className="row">
            <div className="col-12">
              <div className="full-width stores-page">
                <h1 className="stores-page-heading full-width">
                  Popular websites with Coupon Codes
                </h1>
                <div className="full-width stores-list-block">
                  <ul className="stores-list list-unstyled full-width">
                    {storesList !== null
                      ? storesList.map((data: any, index: number) => (
                          <li key={index + "store"}>
                            <Link
                              to={data.storeUrl}
                              className="each-store-item full-width"
                            >
                              <div className="store-image full-width">
                                <img src={data.storeImage} alt={data.store} />
                              </div>
                              <div className="store-name full-width">
                                {data.store}
                              </div>
                            </Link>
                          </li>
                        ))
                      : ""}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Stores;
