import { Link } from "react-router-dom";

// Custom imports
import "./coupon.scss";
import img1 from "../../assets/images/logos/1.jpeg";

interface CouponsProptypes {
  link: string;
  image: string;
  couponTitle: string;
  couponDesc: string;
  store: "string";
  type: "coupon" | "deal";
  couponCode?: string;
  storeDetail?: boolean;
  bigText?: string;
  smallText?: string;
  promoCouonCode?: string;
  btnOnClick: any;
}

const Coupon = (props: CouponsProptypes) => {
  return (
    <div
      className={
        props.storeDetail
          ? "full-width coupon store-detail"
          : "full-width coupon"
      }
    >
      {props.storeDetail ? (
        <div className="coupon-brand-image save-text-block">
          <div className="save-big-text">{props.bigText}</div>
          <div className="save-small-text">{props.smallText}</div>
          <div className="save-promoCouponCode-text">
            {props.promoCouonCode}
          </div>
        </div>
      ) : (
        <button
          className="coupon-brand-image"
          type="button"
          onClick={props.btnOnClick}
        >
          <img src={props.image} alt="Title of brand" />
        </button>
      )}
      <div className="coupon-info-block">
        <a href="#" className="coupon-info-title full-width">
          {props.couponTitle}
        </a>
        <div className="copon-info-desc full-width">{props.couponDesc}</div>
        <div className="coupon-other-info full-width">
          <span className="each-tag">{props.store}</span>
        </div>
      </div>
      <div className="coupon-code-block">
        <button
          className={
            props.type === "coupon"
              ? "coupon-deal-btn coupon"
              : "coupon-deal-btn deal"
          }
          type="button"
          onClick={props.btnOnClick}
        >
          <span className="show-coupon-title">
            {props.type === "coupon" ? "Get Coupon Code" : "Get Deal"}
          </span>
          {props.couponCode ? (
            <span className="hide-code">{props.couponCode}</span>
          ) : (
            ""
          )}
        </button>
      </div>
    </div>
  );
};

export default Coupon;
