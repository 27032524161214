import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// custom imports
import Footer from "../components/Footer";
import Header from "../components/Header";
import Categories from "../pages/Categories";
import CategoryDetail from "../pages/CategoriesDetail";
import HomePage from "../pages/Home";
import Page404 from "../pages/Page404";
import StoreDetail from "../pages/StoreDetail";
import Stores from "../pages/Stores";

const PageRouter = () => {
  return (
    <Router>
      <div className="full-width outer-page">
        <Header />
        <div className="full-width page">
          <Switch>
            <Route exact path="/">
              <HomePage />
            </Route>
            <Route exact path="/categories">
              <Categories />
            </Route>

            <Route exact path="/categories/:id">
              <CategoryDetail />
            </Route>

            <Route exact path="/stores">
              <Stores />
            </Route>

            <Route exact path="/stores/:id">
              <StoreDetail />
            </Route>

            <Route>
              <Page404 />
            </Route>
          </Switch>
        </div>
        <Footer />
      </div>
    </Router>
  );
};

export default PageRouter;
